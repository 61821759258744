import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const AutomationEngineering = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="AutomationEngineering"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Brings a shift from user interface tests to multi-layered automated tests pyramid and lifecycle automation across the delivery pipeline, to enable 10x more frequent releases and 20x faster build creation with 90% lower failures due to build changes. We enable early automation in sprints, to fail fast and accelerate quality by learning faster. Our best-in-class automation engineering service is characterized by:</p>
                        <ul className="circle-list">
                            <li className="mt-2">Shift left, shift right and spherical shifting techniques, to look for quality opportunities across and beyond SDLC</li>
                            <li className="mt-2">New and emerging technologies, and process knowhow to enable continuous testing in large Agile and DevOps programmes</li>
                            <li className="mt-2">360 Degree feedback mechanism, to monitor and analyze production data and feed it back to quality engineering process</li>
                            <li className="mt-2">Blend internal IP/accelerators with external solutions to optimize testing and increase code coverage</li>
                            <li className="mt-2">Build a culture of quality and enhance quality accountability </li>
                            <li className="mt-2">Embed AI/ML in quality engineering lifecycle automation</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AutomationEngineeringPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AutomationEngineering.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AutomationEngineering;
